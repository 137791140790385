import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../modules/layout';
import Main from '../modules/main';
import { TransitionState } from 'gatsby-plugin-transition-link';
import { LAYOUT_TYPES } from '../modules/layout';

const getLayout = (page, url) => {
  const items = [
    {
      __typename: LAYOUT_TYPES.HELMET,
      summary: page.pageSummary,
      structuredData: page.structuredData,
      url: url,
    },
  ];

  return items;
};

class PageTemplate extends React.Component {
  render() {
    const url = this.props.location.href;
    const path = this.props.location.pathname;
    const page = get(this.props, 'data.contentfulPage');
    const meta = page?.meta ? JSON.parse(page.meta?.internal?.content) : null;

    const { pageColorScheme = 'white', addDefaultContactBlock } = page;
    let layout = [...page.layout, ...getLayout(page, url)];

    if (addDefaultContactBlock) {
      const defaultContactBlock = get(this.props, 'pageContext.contactBlock');
      if (defaultContactBlock) {
        layout = [...layout, defaultContactBlock];
      }
    }
    return (
      <TransitionState>
        {({ transitionStatus }) => (
          <Main
            {...this.props}
            transitionStatus={transitionStatus}
            page={page}
            pageBackgroundColor={meta?.pageClass?.bgColor}
          >
            <span className={meta?.pageClass}>
              <Layout
                {...page}
                layout={layout}
                colorScheme={pageColorScheme}
                contentType={'page'}
                transitionStatus={transitionStatus}
                path={path}
              />
            </span>
          </Main>
        )}
      </TransitionState>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      pageColorScheme: colorScheme
      slug
      parent: contentfulparent
      addDefaultContactBlock
      pageSummary {
        title
        image {
          file {
            url
          }
        }
        content {
          childMarkdownRemark {
            excerpt
          }
        }
      }
      structuredData {
        ...StructuredDataFields
      }
      meta {
        internal {
          content
        }
      }
      subPages {
        __typename
        ... on Node {
          ... on ContentfulPage {
            name
            slug
            label: name
            contentfulparent
            pageSummary {
              title
              content {
                childMarkdownRemark {
                  html
                }
              }
              colorScheme
              meta {
                label
              }
            }
            colorScheme
          }
          ... on ContentfulContentGeneric {
            title
            image {
              file {
                url
              }
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            richContent {
              document: json
            }
            colorScheme
            backgroundColor
            links {
              ...LinkFields
              ...PageLinkFields
            }
            meta {
              label
              drawing
            }
          }
        }
      }
      ...LayoutPageFields
    }
  }
`;
